import React from "react";

export default function Logo({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        d="M13.287 11.881a.882.882 0 01-.62-1.512c1.1-1.083 1.244-2.517.386-3.833-1.506-2.316-1.418-4.541.25-6.268a.885.885 0 011.249-.02c.35.339.36.897.02 1.248-.397.412-1.607 1.664-.038 4.076 1.31 2.012 1.063 4.389-.628 6.055a.878.878 0 01-.62.254zm4.186 0a.884.884 0 01-.62-1.512c1.1-1.083 1.244-2.517.387-3.833-1.507-2.316-1.419-4.541.249-6.268a.886.886 0 011.249-.02c.35.339.359.897.02 1.248-.398.412-1.607 1.664-.039 4.076 1.31 2.012 1.064 4.389-.627 6.055a.877.877 0 01-.619.254zm4.186 0a.883.883 0 01-.621-1.512c1.1-1.083 1.244-2.517.387-3.833-1.506-2.316-1.42-4.541.25-6.268a.886.886 0 011.248-.02c.35.339.36.897.022 1.248-.399.412-1.609 1.664-.04 4.076 1.31 2.012 1.064 4.389-.627 6.055a.878.878 0 01-.62.254zM0 36.123v.567a2.658 2.658 0 002.65 2.65h30.426a2.657 2.657 0 002.65-2.65v-.567H0zm36.355-21.05h-5.102c-.038 0-.074.01-.112.01-.037-.002-.072-.01-.11-.01H3.68a1.771 1.771 0 00-1.766 1.766v5.555c0 6.317 4.233 10.847 10.285 12.846h10.317c5.524-1.826 9.531-5.758 10.187-11.238h3.654a3.07 3.07 0 003.062-3.061v-2.807a3.072 3.072 0 00-3.063-3.062zm.767 5.868a.777.777 0 01-.767.765h-3.557V17.37h3.557c.416 0 .767.35.767.765v2.807z"
        fill="#F56B11"
      />
      <path
        d="M11.417 23.438a5.312 5.312 0 113.124 4.842c-.891.205-1.85.382-2.292.461a.705.705 0 01-.817-.828c.084-.432.27-1.359.483-2.227a5.295 5.295 0 01-.498-2.248zm4.352-2.97c-.313.167-.56.427-.746.771a.531.531 0 00.933.506c.105-.191.212-.286.314-.34.106-.057.25-.092.46-.092.158 0 .344.056.48.169.122.099.227.258.227.539 0 .138-.053.202-.416.487-.319.25-.823.658-.823 1.46a.531.531 0 001.062 0c0-.261.117-.388.417-.625l.06-.046c.274-.21.763-.585.763-1.276 0-.6-.249-1.061-.615-1.362a1.852 1.852 0 00-1.156-.408c-.322 0-.653.052-.96.216zm1.668 5.449a.708.708 0 10-1.416 0 .708.708 0 001.416 0z"
        fill="#fff"
      />
      <path
        d="M16.658 29.458a5.294 5.294 0 003.613 1.417c.779 0 1.52-.168 2.188-.47a48.91 48.91 0 002.3.46.706.706 0 00.815-.832 52.77 52.77 0 00-.489-2.222 5.317 5.317 0 00-3.076-7.27 6.02 6.02 0 01-5.35 8.917z"
        fill="#fff"
      />
    </svg>
  );
}
